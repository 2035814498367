import { SxProps, useTheme } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import scssTypography from 'src/assets/styles/variables/typography.module.scss';

interface IDataGrid extends DataGridProProps {
  disableColumnTopBorder?: boolean;
  disableCellBorder?: boolean;
  disableCellOutline?: boolean;
}

export const dataGridCellBorderLeftThickClassName = 'MuiDataGrid-cell--thick-border-left';
export const dataGridCellBorderRightThickClassName = 'MuiDataGrid-cell--thick-border-right';
export const dataGridCellFontWeightBoldClassName = 'MuiDataGrid-cell--font-weight-bold';
export const dataGridCellFontWeightSemiBoldClassName = 'MuiDataGrid-cell--font-weight-semi-bold';
export const dataGridCellFontWeightMediumClassName = 'MuiDataGrid-cell--font-weight-medium';
export const dataGridCellFontFamilyMonospaceClassName = 'MuiDataGrid-cell--font-family-monospace';

const cellBorderStyles: SxProps = {
  '.MuiDataGrid-virtualScrollerContent': {
    borderRight: '1px solid rgba(0, 0, 0, .12)',
    borderBottom: '1px solid rgba(0, 0, 0, .12)',
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid rgba(0, 0, 0, .12)',

      // the "spacer" div inserted by mui does not need a border
      '&:last-child:empty:not(tabindex)': {
        borderRightWidth: '0',
      },
    },
  },
};

const columnTopBorderStyles: SxProps = {
  '.MuiDataGrid-columnHeaders': {
    borderRadius: 0,
    borderBottom: '2px solid #000',

    '&:not(:has(.MuiDataGrid-columnHeader--filledGroup))': {
      borderTop: '2px solid #000',
    },
  },
  '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--filledGroup)': {
    borderTop: '2px solid #000',
  },
};

// modifiers
const modifiers: SxProps = {
  '.MuiDataGrid-main .MuiDataGrid-row': {
    [`& .${dataGridCellBorderLeftThickClassName}`]: {
      borderLeft: '1px solid #000',
    },

    [`& .${dataGridCellBorderRightThickClassName}`]: {
      borderRight: '1px solid #000',
    },
  },
  [`& .${dataGridCellFontWeightBoldClassName}`]: {
    fontWeight: scssTypography.fontWeightBold,
  },
  [`& .${dataGridCellFontWeightSemiBoldClassName}`]: {
    fontWeight: scssTypography.fontWeightSemibold,
  },
  [`& .${dataGridCellFontWeightMediumClassName}`]: {
    fontWeight: scssTypography.fontWeightMedium,
  },
  [`& .${dataGridCellFontFamilyMonospaceClassName}`]: {
    fontFamily: scssTypography.fontFamilyNumeric,
  },
};

export const DataGrid = ({
  disableColumnTopBorder = false,
  disableCellBorder = false,
  disableCellOutline = false,
  sx,
  ...rest
}: IDataGrid) => {
  const theme = useTheme();

  const styles: SxProps = useMemo(
    () => ({
      border: 0,
      borderRadius: 0,

      '.MuiDataGrid-columnHeaders': {
        borderBottom: '2px solid #000',
      },

      '.MuiDataGrid-columnHeaderTitleContainer': {
        gap: theme.spacing(1),
      },

      '.MuiDataGrid-columnHeader--filledGroup': {
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },

        '& .MuiDataGrid-columnHeaderTitleContainer': {
          borderBottomWidth: 0,
        },
      },

      '.MuiDataGrid-columnHeaderRow': {
        '&:only-child': {
          borderTop: '2px solid #000',
          borderBottom: '2px solid #000',
        },
      },

      '.MuiDataGrid-pinnedRows--bottom': {
        '& .MuiDataGrid-row': {
          borderTop: '2px solid #000',
        },

        '& .MuiDataGrid-cell': {
          fontWeight: 500,
          borderBottomWidth: 0,
        },

        '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
          borderRightWidth: 0,
          borderLeftWidth: 0,
        },
      },

      '.MuiDataGrid-pinnedColumnHeaders': {
        top: 'auto',
      },

      '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
        boxShadow: 'none',
      },

      'div:not(.MuiDataGrid-pinnedRows--bottom)': {
        '& > .MuiDataGrid-pinnedColumns--left': {
          borderRight: '1px solid rgba(0, 0, 0, .12)',
        },

        '& > .MuiDataGrid-pinnedColumns--right': {
          borderLeft: '1px solid rgba(0, 0, 0, .12)',
        },
      },

      '.success': {
        color: '#007a00',
      },

      '.error': {
        color: '#c92726',
      },

      '.bgSuccess': {
        backgroundColor: '#007a00',
      },

      '.bgError': {
        backgroundColor: '#c92726',
      },

      '.bgInfo': {
        backgroundColor: '#3A66D5',
      },

      '.bgGray': {
        backgroundColor: '#dedede',
      },

      // modifiers
      '.MuiDataGrid-main': {
        [`.${dataGridCellBorderLeftThickClassName}`]: {
          borderLeft: '1px solid #000',
        },

        [`.${dataGridCellBorderRightThickClassName}`]: {
          borderRight: '1px solid #000',
        },
      },

      '.MuiDataGrid-columnHeader--alignRight': {
        textAlign: 'right',
      },
    }),
    [theme],
  );

  const cellOutlineStyles: SxProps = useMemo(
    () => ({
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-columnHeaderCheckbox:focus-within':
        {
          outline: 'none !important',
        },
    }),
    [],
  );

  return (
    <DataGridPro
      {...rest}
      sx={{
        ...styles,
        ...modifiers,
        ...sx,
        ...(!disableColumnTopBorder && columnTopBorderStyles),
        ...(!disableCellBorder && cellBorderStyles),
        ...(disableCellOutline && cellOutlineStyles),
      }}
    />
  );
};
