import { IAutocompleteWithRadiosOption, IFilter } from './components/AutocompleteWithRadios';
import { ICompetencePickerOption } from './components/CompetencePicker';

export interface IAssignDialogFormForm {
  query: string;
  startDate: Date | null;
  endDate: Date | null;
  hours: string;
}

export interface IAssignDialogFormFilters {
  manager: IFilter;
  department: IAutocompleteWithRadiosOption[];
  competence: ICompetencePickerOption[];
  legalEntity: IFilter;
}

export const ASSIGN_DIALOG_FORM: IAssignDialogFormForm = {
  query: '',
  startDate: new Date(),
  endDate: null,
  hours: '',
};

export const ASSIGN_DIALOG_FILTERS: IAssignDialogFormFilters = {
  manager: {
    isInclude: true,
    values: [],
  },
  department: [],
  competence: [],
  legalEntity: {
    isInclude: true,
    values: [],
  },
};
