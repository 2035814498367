export default {
  Entities: [
    {
      Properties: {
        ContractHourlyRateID: 5000081,
        ProjectSubContractID: 27,
        Name: 'Internal time',
        Amount: 500.0,
        Type: 'Standard',
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/contract-hourly-rates/dropdown-content?taskId=402',
      Rel: 'self',
    },
  ],
};
