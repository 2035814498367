import {
  AutocompleteCloseReason,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Popper,
} from '@mui/material';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { Autocomplete, Box, Paper, Radio, RadioGroup, Stack } from 'src/components/mui-components';
import { useGetWindowSize } from 'src/hooks/useGetWindowSize';
import { useMenu } from 'src/hooks/useMenu';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { DataAutomationId } from 'src/utils/ts-utils';
import styles from './AutocompleteWithRadios.module.scss';
import { IAutocompleteWithRadiosOption, IFilter } from './AutocompleteWithRadios.types';

interface IAutocompleteWithRadios extends DataAutomationId {
  filterId: string;
  placeholder: string;
  setValue: (v: IFilter) => void;
  value: IFilter;
}

export const AutocompleteWithRadios = ({
  filterId,
  placeholder = 'Search',
  setValue,
  value,
  ...rest
}: IAutocompleteWithRadios) => {
  const { t } = useTranslation('assignFlow');
  const { anchorEl, menuIsOpen, setAnchorEl } = useMenu();

  const { w } = useGetWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const [elementWidth, setElementWidth] = useState(0);

  const contentUrl = `api/v2/filters/${filterId}/content`;
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { dropdownList, isFetching } = useGetDropdownWithSearchAPI({
    key: `filterInput${filterId}`,
    MSWKey: `FILTER_INPUT_${filterId}`,
    path: `/${contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: '',
    enabled: menuIsOpen,
  });

  const [isInclude, setIsInclude] = useState<'is' | 'is-not'>('is');

  const popperOnClose = () => {
    setAnchorEl(null);
  };

  const radioOnChange = (_: ChangeEvent<HTMLInputElement>, v: string) => {
    if (v !== 'is' && v !== 'is-not') {
      return;
    }

    setIsInclude(v);
    setValue?.({
      ...value,
      isInclude: v === 'is',
    });
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const { width } = ref.current.getBoundingClientRect();
    setElementWidth(width);
  }, [w]);

  return (
    <>
      <Box onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Autocomplete
          data-automation-id={`${rest['data-automation-id']}Autocomplete`}
          inputStyles={{ height: 23 }}
          isOptionEqualToValue={(o, v) => o.value === v.value}
          label={value.values.length ? placeholder : undefined}
          multiple
          options={[]}
          placeholder={placeholder}
          readOnly
          ref={ref}
          size="small"
          value={value.values}
        />
      </Box>
      {menuIsOpen && (
        <Popper anchorEl={anchorEl} className={styles.Popper} open={menuIsOpen} placement="bottom">
          <ClickAwayListener onClickAway={popperOnClose}>
            <Paper className={styles.Paper} elevation={4} sx={{ width: elementWidth }}>
              <Stack gap={2}>
                <FormControl>
                  <RadioGroup
                    className={styles.RadioGroup}
                    name="IsOrIsNotRadio"
                    onChange={radioOnChange}
                    row
                    value={isInclude}
                  >
                    <FormControlLabel
                      aria-label={t('AutocompleteWithRadios.Is')}
                      control={<Radio />}
                      label={t('AutocompleteWithRadios.Is')}
                      value="is"
                    />
                    <FormControlLabel
                      aria-label={t('AutocompleteWithRadios.IsNot')}
                      control={<Radio />}
                      label={t('AutocompleteWithRadios.IsNot')}
                      value="is-not"
                    />
                  </RadioGroup>
                </FormControl>
                <Autocomplete
                  data-automation-id={`${rest['data-automation-id']}AutocompleteWithRadios`}
                  disableCloseOnSelect
                  disablePortal
                  getOptionDisabled={() => isFetching}
                  inputProps={{ autoFocus: true }}
                  inputStyles={{ height: 23 }}
                  isOptionEqualToValue={(o, v) => o.value === v.value}
                  label={placeholder}
                  loading={isFetching}
                  multiple
                  noOptionsText="No labels"
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === 'keydown' &&
                      (event as KeyboardEvent).key === 'Backspace' &&
                      reason === 'removeOption'
                    ) {
                      return;
                    }
                    setValue?.({
                      isInclude: isInclude === 'is',
                      values: newValue as IAutocompleteWithRadiosOption[],
                    });
                  }}
                  onClose={(_, reason: AutocompleteCloseReason) => {
                    if (reason === 'escape') {
                      popperOnClose();
                    }
                  }}
                  open
                  options={dropdownList}
                  placeholder={placeholder}
                  PopperComponent={Popper}
                  size="small"
                  value={value.values}
                />
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};
