import { Divider, MenuItem, MenuList } from '@mui/material';
import { format } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import { ElementRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  IBulkAssignReq,
  IEmployeeSearchResProperties,
  usePostBulkAssign,
  usePostEmployeeSearch,
} from 'src/apis/resourcePlannerAPI';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { convertObjectValueToNumber } from 'src/screens/ResourcePlanner/helper/convertObjectValueToNumber';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { useResourcePlannerStore } from 'src/stores/ResourcePlannerStore/ResourcePlannerStore';
import { useDebounce } from 'use-debounce';
import {
  ASSIGN_DIALOG_FILTERS,
  ASSIGN_DIALOG_FORM,
  AssignDialogForm,
  IAssignDialogFormFilters,
  IAssignDialogFormForm,
} from '../AssignDialogForm';
import { EmployeeWithCompetence } from '../EmployeeWithCompetence';
import { PleaseSearch } from '../EmptyStates/PleaseSearch';
import { TaskTable } from '../TaskTable';
import { ToastTrans } from '../ToastTrans';
import styles from './AssignProjectToResourceDialog.module.scss';

interface IAssignProjectToResourceDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const AssignProjectToResourceDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
}: IAssignProjectToResourceDialog) => {
  type TTaskTableHandle = ElementRef<typeof TaskTable>;
  const taskTableRef = useRef<TTaskTableHandle>(null);

  type TAssignDialogFormHandle = ElementRef<typeof AssignDialogForm>;
  const assignDialogFormRef = useRef<TAssignDialogFormHandle>(null);

  const { t } = useTranslation('assignFlow');
  const confirm = useConfirm();
  const { dateEnd } = useResourcePlannerStore();
  const { confirmOptions, name, workItemSourceReferenceId: projectId } = useAssignFlowStore();

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<IAssignDialogFormFilters>(ASSIGN_DIALOG_FILTERS);
  const [form, setForm] = useState<IAssignDialogFormForm>({
    ...ASSIGN_DIALOG_FORM,
    endDate: dateEnd,
  });
  const [debouncedForm] = useDebounce(form, 500);
  const { department, competence, manager, legalEntity } = filters;
  const { query, startDate, endDate, hours } = debouncedForm;

  const [selectedResource, setSelectedResource] = useState<IEmployeeSearchResProperties>();
  const [hoursList, setHoursList] = useState<{
    [id: string]: string;
  }>({});

  const enableSearch =
    !!competence?.length ||
    !!department?.length ||
    !!hours ||
    !!legalEntity?.values.length ||
    !!manager?.values.length ||
    !!query;

  const {
    data: d,
    isError,
    isFetching,
  } = usePostEmployeeSearch(
    {
      competence,
      department,
      endDate,
      hours,
      legalEntity,
      manager,
      query,
      startDate,
    },
    enableSearch,
  );

  const addedHours = Object.values(hoursList)
    .filter((h) => !Number.isNaN(Number(h)))
    .reduce((a, b) => a + Number(b), 0);
  const data = useMemo(() => d ?? [], [d]);
  const showResults = !!data.length;
  const getSelectedTasks = useCallback(() => taskTableRef.current?.getFormValues() ?? [], []);

  const cleanUp = useCallback(() => {
    setFilters(ASSIGN_DIALOG_FILTERS);
    setForm({
      ...ASSIGN_DIALOG_FORM,
      endDate: dateEnd,
    });
    setHoursList({});
    setDialogIsOpen(false);
    setPage(1);
    setSelectedResource(undefined);
  }, [dateEnd, setDialogIsOpen]);

  const dialogOnClose = () => {
    if (selectedResource || getSelectedTasks().length) {
      confirm(confirmOptions).then(cleanUp);
      return;
    }

    cleanUp();
  };

  const applyFilters = () => {
    const filterValues = assignDialogFormRef.current?.getFilterValues();
    setFilters((prev) => filterValues ?? prev);
  };

  const resetFilters = useCallback(() => {
    setFilters(ASSIGN_DIALOG_FILTERS);
  }, []);

  const backButtonOnClick = useCallback(() => {
    if (page === 2) {
      setHoursList({});
    }

    setPage((prev) => prev - 1);
  }, [page]);

  const employeeLabel = useMemo(() => {
    if (page === 2 && selectedResource) {
      return selectedResource.fullName;
    }

    return t('Employee');
  }, [page, selectedResource, t]);

  const bulkAssignOnSuccess = () => {
    cleanUp();
    toast.success(
      <ToastifyAlert
        title={t('Toast.SuccessTitle')}
        description={<ToastTrans l="Toast.AssignXtoYSuccess" t={t} x={name} y={employeeLabel} />}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const { mutate: postBulkAssign, isLoading } = usePostBulkAssign(bulkAssignOnSuccess);

  const nextButtonOnClick = useCallback(() => {
    if (!selectedResource) {
      return;
    }

    if (page === 1) {
      setPage(2);
      return;
    }

    if (!getSelectedTasks()?.length || !taskTableRef.current?.getFormIsValid()) {
      return;
    }

    const assignReqBody: IBulkAssignReq[] = getSelectedTasks().map((task) => ({
      allocationEndDate: format(new Date(task.allocationEndDate), 'yyyy-MM-dd'),
      allocationStartDate: format(new Date(task.allocationStartDate), 'yyyy-MM-dd'),
      ...convertObjectValueToNumber({
        budgetHours: task.hours ?? 0,
        hourlyRateId: task.hourlyRate || task.suggestedHourlyRateId,
        projectId,
        resourceSourceReferenceId: selectedResource.userId,
        workItemSourceReferenceId: task.workItemSourceReferenceId,
      }),
    }));

    postBulkAssign(assignReqBody);
  }, [selectedResource, page, getSelectedTasks, postBulkAssign, projectId]);

  const employeeLabelElement = useMemo(() => {
    if (page === 2 && selectedResource) {
      return <b>{employeeLabel}</b>;
    }

    return employeeLabel;
  }, [employeeLabel, page, selectedResource]);

  const dialogTitle = useMemo(() => {
    if (page === 2) {
      return (
        selectedResource && (
          <>
            <EmployeeWithCompetence addedHours={addedHours} resource={selectedResource} />
            <Divider className={styles.Divider} />
          </>
        )
      );
    }

    return (
      <AssignDialogForm
        applyOnClick={applyFilters}
        filters={filters}
        form={form}
        ref={assignDialogFormRef}
        resetOnClick={resetFilters}
        setForm={setForm}
        showDivider
      />
    );
  }, [addedHours, filters, form, page, resetFilters, selectedResource]);

  const dialogContent = useMemo(() => {
    if (page === 2) {
      return (
        <Box className={styles.TableContainer}>
          <TaskTable
            endDate={endDate ?? dateEnd}
            projectId={projectId}
            ref={taskTableRef}
            setHoursList={setHoursList}
            startDate={startDate ?? new Date()}
            userId={selectedResource?.userId}
          />
        </Box>
      );
    }

    return (
      <ResponseHandler
        EmptyComponent={
          <Typography textAlign="center">{t('AssignProjectToResourceDialog.NoResults')}</Typography>
        }
        FreshComponent={<PleaseSearch />}
        isEmpty={!data.length}
        isError={isError}
        isFresh={!enableSearch}
        isLoading={isFetching}
        LoadingComponent={<CircularProgress />}
      >
        <MenuList className={styles.Menu}>
          {data.map(({ properties: r }) => (
            <MenuItem
              className={styles.MenuItem}
              key={r.userId}
              onClick={() =>
                setSelectedResource((prev) => {
                  if (prev?.userId === r.userId) {
                    nextButtonOnClick();
                  }
                  return r;
                })
              }
              selected={r.userId === selectedResource?.userId}
            >
              <EmployeeWithCompetence query={query} resource={r} />
            </MenuItem>
          ))}
        </MenuList>
      </ResponseHandler>
    );
  }, [
    data,
    dateEnd,
    enableSearch,
    endDate,
    isError,
    isFetching,
    nextButtonOnClick,
    page,
    projectId,
    query,
    selectedResource?.userId,
    startDate,
    t,
  ]);

  const dialogActions = useMemo(() => {
    if (page === 2) {
      return (
        <Stack direction="row" flex={1} justifyContent="space-between">
          <Button disabled={isLoading} onClick={backButtonOnClick}>
            {t('Back')}
          </Button>
          <Stack direction="row">
            <Button disabled={isLoading} onClick={cleanUp} variant="outlined">
              {t('Cancel')}
            </Button>
            <LoadingButton isLoading={isLoading} onClick={nextButtonOnClick} variant="contained">
              {t('Assign')}
            </LoadingButton>
          </Stack>
        </Stack>
      );
    }

    return (
      <>
        <Button onClick={cleanUp} variant="outlined">
          {t('Cancel')}
        </Button>
        <Button
          disabled={!showResults || !selectedResource}
          onClick={nextButtonOnClick}
          variant="contained"
        >
          {t('Next')}
        </Button>
      </>
    );
  }, [
    backButtonOnClick,
    cleanUp,
    isLoading,
    nextButtonOnClick,
    page,
    selectedResource,
    showResults,
    t,
  ]);

  useEffect(() => {
    setForm((prev) => ({ ...prev, endDate: dateEnd }));
  }, [dateEnd]);

  useEffect(() => {
    applyFilters();
  }, [endDate, hours, query, startDate]);

  return (
    <Dialog
      fullWidth
      maxWidth={page === 1 ? 'md' : 'xl'}
      onClose={dialogOnClose}
      open={dialogIsOpen}
      PaperProps={{
        sx: {
          height: page === 1 ? 'auto' : '100%',
        },
      }}
    >
      <DialogTitle>
        <Box className={styles.DialogTitle}>
          <Trans
            i18nKey="AssignXtoY"
            key="AssignXtoY"
            defaults={t('AssignXtoY')}
            values={{ x: name, y: employeeLabel }}
            components={[<b key={0}>{name}</b>, employeeLabelElement]}
          />
        </Box>
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={styles.DialogContent}>{dialogContent}</DialogContent>
      <DialogActions className={styles.DialogActions}>{dialogActions}</DialogActions>
    </Dialog>
  );
};
