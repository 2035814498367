export interface IResourcePlannerStoreValue {
  dateEnd: Date;
  rpLoading: boolean;
  selectedViewOptions: {
    [key: string]: string;
  };
}

export const INITIAL_RP_STORE: IResourcePlannerStoreValue = {
  dateEnd: new Date(),
  rpLoading: true,
  selectedViewOptions: {},
};
