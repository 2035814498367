import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { TFuncKey, Trans } from 'react-i18next';

interface IToastTrans {
  l: TFuncKey<'assignFlow'>;
  t: TFunction;
  x: ReactNode;
  y: ReactNode;
}

export const ToastTrans = ({ l, t, x, y }: IToastTrans) => (
  <Trans
    i18nKey={l}
    key={l}
    defaults={t(l)}
    values={{ x, y }}
    components={[
      <span key={0} style={{ fontWeight: 500 }}>
        {x}
      </span>,
      <span key={1} style={{ fontWeight: 500 }}>
        {y}
      </span>,
    ]}
  />
);
