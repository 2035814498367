import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getPathWithQueries } from 'src/utils/url/url';
import { TUnallocatedEmployeesRes } from './getUnallocatedEmployeesAPI.types';

const GET_UNALLOCATED_EMPLOYEES_URL_PATH = '/api/v2/projects/{projectId}/employee-unallocated';
const GET_UNALLOCATED_EMPLOYEES_KEY = 'UnallocatedEmployees';

interface IGetUnallocatedEmployees {
  projectId?: string;
  taskId?: string;
}

const getUnallocatedEmployees = ({
  projectId = '',
  taskId,
}: IGetUnallocatedEmployees): Promise<TUnallocatedEmployeesRes> =>
  getFetch({
    path: getPathWithQueries(GET_UNALLOCATED_EMPLOYEES_URL_PATH.replace('{projectId}', projectId), {
      taskId,
    }),
    key: GET_UNALLOCATED_EMPLOYEES_KEY,
  });

export const useGetUnallocatedEmployees = (params: IGetUnallocatedEmployees, enable?: boolean) => {
  const {
    data: { entities } = {},
    isError,
    isFetching,
  } = useQuery(
    [GET_UNALLOCATED_EMPLOYEES_KEY, params.projectId, params.taskId],
    () => getUnallocatedEmployees(params),
    {
      enabled: enable && !!params.projectId && !!params.taskId,
    },
  );

  return { data: entities, isError, isFetching };
};
