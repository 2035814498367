export default {
  Entities: [
    {
      Properties: {
        WbsNo: '1',
        WorkItemSourceReferenceId: 926,
        Name: 'Development',
        No: 'O0903',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 0.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '1.1',
        WorkItemSourceReferenceId: 928,
        Name: 'Develop                               ',
        No: 'O0905',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 20.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '1.2',
        WorkItemSourceReferenceId: 929,
        Name: 'Fix',
        No: 'O0906',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 10.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '1.3',
        WorkItemSourceReferenceId: 930,
        Name: 'Testing',
        No: 'O0907',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 20.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '1.4',
        WorkItemSourceReferenceId: 931,
        Name: 'Regression',
        No: 'O0908',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 5.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '1.5',
        WorkItemSourceReferenceId: 932,
        Name: 'Maintenance',
        No: 'O0909',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 25.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '2',
        WorkItemSourceReferenceId: 927,
        Name: 'Release',
        No: 'O0904',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 0.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '2.1',
        WorkItemSourceReferenceId: 933,
        Name: 'Release notes',
        No: 'O0910',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 5.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '2.2',
        WorkItemSourceReferenceId: 934,
        Name: 'Patch',
        No: 'O0911',
        StartDate: '2023-05-01T00:00:00',
        EndDate: '2023-08-31T00:00:00',
        BudgetHour: 15.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '3',
        WorkItemSourceReferenceId: 946,
        Name: 'testing',
        No: 'O0923',
        StartDate: '2023-11-08T00:00:00',
        EndDate: '2023-11-08T00:00:00',
        BudgetHour: 0.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '3.1',
        WorkItemSourceReferenceId: 947,
        Name: 'Sub testing',
        No: 'O0924',
        StartDate: '2023-11-08T00:00:00',
        EndDate: '2023-11-08T00:00:00',
        BudgetHour: 0.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '3.1.1',
        WorkItemSourceReferenceId: 948,
        Name: 'Sub testing 1',
        No: 'O0925',
        StartDate: '2023-11-08T00:00:00',
        EndDate: '2023-11-08T00:00:00',
        BudgetHour: 15.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '3.1.2',
        WorkItemSourceReferenceId: 949,
        Name: 'Sub testing 2',
        No: 'O0926',
        StartDate: '2023-11-08T00:00:00',
        EndDate: '2023-11-08T00:00:00',
        BudgetHour: 0.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '3.1.2.1',
        WorkItemSourceReferenceId: 950,
        Name: 'Sub task 2.1',
        No: 'O0927',
        StartDate: '2023-11-08T00:00:00',
        EndDate: '2023-11-08T00:00:00',
        BudgetHour: 0.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
    {
      Properties: {
        WbsNo: '3.1.2.1.1',
        WorkItemSourceReferenceId: 951,
        Name: 'Sub task 2.1.1',
        No: 'O0928',
        StartDate: '2023-11-08T00:00:00',
        EndDate: '2023-11-08T00:00:00',
        BudgetHour: 10.0,
        SuggestedHourlyRateId: 5001703,
        SuggestedHourlyRateAmount: 1000.0,
        HourlyRateCurrencyAbb: 'DKK',
        IsShownHourlyRateDropDown: true,
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/projects/108/tasks-unallocated?userId=1',
      Rel: 'self',
    },
  ],
};
