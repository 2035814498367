import { DialogActions } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeleteWorkItemRelationship } from 'src/apis/resourcePlannerAPI';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { ToastTrans } from '../ToastTrans';

interface IRemoveTaskOrResourceDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const RemoveTaskOrResourceDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
}: IRemoveTaskOrResourceDialog) => {
  const { t } = useTranslation('assignFlow');
  const {
    isWorkItem,
    name,
    parentTask,
    resourceId,
    resourceSourceReferenceId,
    resourceType,
    workItemId,
    workItemSourceReferenceId,
    workItemType,
  } = useAssignFlowStore();

  const dialogOnClose = () => {
    setDialogIsOpen(false);
  };

  const { mutateAsync: deleteWorkItemRelationship } = useDeleteWorkItemRelationship();

  const onDelete = () => {
    setDialogIsOpen(false);
    toast.promise(
      deleteWorkItemRelationship({
        resourceId,
        resourceSourceReferenceId,
        resourceType,
        workItemId,
        workItemSourceReferenceId,
        workItemType,
      }),
      {
        pending: {
          render: (
            <ToastifyAlert
              customIcon={<CircularProgress size={20} />}
              description={
                <ToastTrans l="Toast.RemovingItem" t={t} x={name} y={parentTask?.name} />
              }
            />
          ),
        },
        success: {
          render: (
            <ToastifyAlert
              title={t('Toast.SuccessTitle')}
              description={
                <ToastTrans l="Toast.RemoveItemSuccess" t={t} x={name} y={parentTask?.name} />
              }
            />
          ),
        },
      },
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  return (
    <Dialog maxWidth="xs" open={dialogIsOpen} onClose={dialogOnClose}>
      <DialogTitle>
        <Trans
          i18nKey="RemoveXfromY"
          key="RemoveXfromY"
          defaults={t('RemoveXfromY')}
          values={{ x: name, y: parentTask?.name }}
          components={[<b key={0}>{name}</b>, <b key={1}>{parentTask?.name}</b>]}
        />
        ?
      </DialogTitle>
      <DialogContent>
        <Typography>
          {isWorkItem ? t('ConfirmRemoveTask') : t('ConfirmRemoveAllocation')}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogOnClose} variant="outlined">
          {t('Cancel')}
        </Button>
        <Button onClick={onDelete} variant="contained">
          {t('Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
