import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { TextField } from 'src/components/mui-components';
import { useCustomEditComponent } from 'src/screens/ResourcePlanner/hooks';
import { convertNumber, formatNumber, validateNumberInput } from 'src/utils/number';
import { ComponentWrapper } from '../EditableComponents';
import styles from '../EditableComponents.module.scss';

interface IEditableHours extends GridRenderEditCellParams {
  onChange?: () => void;
}

export const EditableHours = ({ field, id, name, onChange, row, value }: IEditableHours) => {
  const apiRef = useGridApiContext();
  const siteLocale = useGetLocale();
  const { t } = useTranslation('assignFlow');
  const { handleChange } = useCustomEditComponent<string>({ field, id });

  const handleBlur = () => {
    const { status, value: v } = validateNumberInput(
      value,
      t('EditableComponents.DecimalFormatValidation'),
    );
    apiRef.current.updateRows([
      {
        error: status === 'error' ? v : '',
        id,
      },
    ]);
  };

  return (
    <ComponentWrapper>
      <TextField
        ariaLabel={t('EditableComponents.HoursLabel', { name })}
        className={styles.EditableHours}
        data-automation-id={`${id}Hours`}
        error={!!row.error}
        errorMessage={row.error}
        onBlur={handleBlur}
        onChange={(e) => {
          handleChange(e.target.value);
          const decimalFormat = formatNumber(e.target.value, siteLocale, 4);
          apiRef.current.updateRows([
            {
              hoursInt: convertNumber(decimalFormat, siteLocale),
              id,
            },
          ]);
          onChange?.();
        }}
        placeholder="0.00"
        size="small"
        status={row.error ? 'error' : 'info'}
        usePopOutValidation
        value={value}
      />
    </ComponentWrapper>
  );
};
