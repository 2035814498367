import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { ForwardedRef, Ref, forwardRef } from 'react';

export interface SelectProps<T> extends MuiSelectProps<T> {
  helperText?: string;
}

const SelectInner = <T,>({ helperText, ...props }: SelectProps<T>, ref: Ref<HTMLDivElement>) => {
  const {
    disabled,
    error,
    fullWidth,
    label,
    labelId,
    required,
    size,
    variant = 'outlined',
  } = props;

  return (
    <MuiFormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      ref={ref}
      required={required}
      size={size}
      variant={variant}
    >
      {label && <MuiInputLabel id={labelId}>{label}</MuiInputLabel>}
      <MuiSelect {...props} />
      {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

export const Select = forwardRef(SelectInner) as <T>(
  props: SelectProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SelectInner>;
