import { Stack, Typography } from 'src/components/mui-components';
import styles from './NoRowsOverlay.module.scss';

interface INoRowsOverlay {
  message: string;
}

export const NoRowsOverlay = ({ message }: INoRowsOverlay) => (
  <Stack className={styles.NoRowsOverlay}>
    <Typography>{message}</Typography>
  </Stack>
);
