import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from 'src/components/mui-components';
import { TCompetence } from '../AssignDialogForm/components/CompetencePicker';
import styles from './CompetenceList.module.scss';

interface ICompetenceList {
  competenceList: TCompetence;
  noOfCompetenceToDisplay?: number;
}

export const CompetenceList = ({
  competenceList = [],
  noOfCompetenceToDisplay = 3,
}: ICompetenceList) => {
  const { t } = useTranslation('assignFlow');

  const sortedList = competenceList.sort((a, b) => b.value - a.value);
  const list = noOfCompetenceToDisplay ? sortedList.slice(0, noOfCompetenceToDisplay) : sortedList;
  const competenceScore = Array.from({ length: 5 }, (_, i) => i + 1);

  return (
    <Stack className={styles.Stack}>
      {list.length ? (
        list.map((c) => (
          <Stack
            key={c.name}
            className={styles.CompetenceItem}
            direction="row"
            justifyContent="space-between"
            title={`${c.name} (${c.value})`}
          >
            <Typography className={styles.Typography}>{c.name}</Typography>
            <Stack alignItems="center" direction="row" gap={0.5}>
              {competenceScore.map((s) => (
                <Box
                  key={`filled-${s}`}
                  className={cx(styles.Box, s <= c.value ? styles.ActiveBox : '')}
                />
              ))}
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography className={styles.Typography}>{t('CompetenceList.NoCompetence')}</Typography>
      )}
    </Stack>
  );
};
