export default {
  Entities: [
    {
      Properties: {
        UserId: 1,
        FullName: 'Anders Nielsen',
        Position: 'Projektleder',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Produktion',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
        Competences: [
          { Id: 11, Name: 'Change Management Mastering', Value: 5 },
          { Id: 1, Name: 'Dansk', Value: 5 },
          { Id: 4, Name: 'Økonomisk styring', Value: 5 },
        ],
      },
    },
    {
      Properties: {
        UserId: 6,
        FullName: 'Carl Chan',
        Position: 'Leder',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Ledelse',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 9,
        FullName: 'Erling Sørensen',
        Position: 'Bogholder',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 90, TotalHours: 90 },
      },
    },
    {
      Properties: {
        UserId: 11,
        FullName: 'Gerda Angelsen',
        Position: 'Ekstern konsulent',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 68, TotalHours: 68 },
      },
    },
    {
      Properties: {
        UserId: 12,
        FullName: 'George Best',
        Position: 'Developer',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Quality',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 13,
        FullName: 'Test TCC Past',
        Position: 'QA',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Ledelse',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 14,
        FullName: 'James  Bong',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 37,
        FullName: 'Jenny Wong',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'R&D ',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 50,
        FullName: '1 Alam  Tam Future',
        Position: 'Developer ',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'R&D ',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 51,
        FullName: 'Aaron Ng',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Ledelse',
        Capacity: { AvailableHours: 149.2, TotalHours: 149.2 },
      },
    },
    {
      Properties: {
        UserId: 53,
        FullName: 'Linda Lim',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 54,
        FullName: 'Amy Aw',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 68, TotalHours: 68 },
      },
    },
    {
      Properties: {
        UserId: 65,
        FullName: 'Åsu Åsu',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Eksterne konsulenter',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 70,
        FullName: '1 Daniel Yap',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 71,
        FullName: '1 Jimmy Lee',
        Position: '',
        LegalEntity: 'TCC',
        Department: 'Administration',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 72,
        FullName: 'Belinda (A) Bong',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Administration',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 73,
        FullName: 'Todd Boehly',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'R&D ',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
    {
      Properties: {
        UserId: 75,
        FullName: 'test test',
        Position: '',
        LegalEntity: 'TimeLog Automatic Dem',
        Department: 'Support',
        Capacity: { AvailableHours: 141.8, TotalHours: 141.8 },
      },
    },
  ],
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/resource-planner/employee-search',
      Rel: 'self',
    },
  ],
};
