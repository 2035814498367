import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker, Grid, IconButton, Stack, TextField } from 'src/components/mui-components';
import styles from './AssignDialogSearch.module.scss';
import { IAssignDialogSearchForm } from './AssignDialogSearch.types';

interface IAssignDialogSearch {
  form: IAssignDialogSearchForm;
  setForm: (f: IAssignDialogSearchForm) => void;
  customLabels?: Partial<{
    endDate: string;
    search: string;
    startDate: string;
  }>;
}

export const AssignDialogSearch = ({ customLabels, form, setForm }: IAssignDialogSearch) => {
  const { t } = useTranslation('assignFlow');

  const updateForm = (f: Partial<IAssignDialogSearchForm>) => {
    setForm({ ...form, ...f });
  };

  return (
    <Grid container className={styles.FormGrid} columnSpacing={5.75} rowSpacing={2}>
      <Grid item xs={12}>
        <TextField
          ariaLabel={customLabels?.search ?? t('AssignDialogForm.SearchLabel')}
          autoFocus
          data-automation-id="SearchForEmployeeInput"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {form.query && (
                  <IconButton onClick={() => updateForm({ query: '' })} size="small">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          label={customLabels?.search ?? t('AssignDialogForm.SearchLabel')}
          onChange={(e) => updateForm({ query: e.target.value })}
          size="small"
          value={form.query}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row">
          <Stack flex={1}>
            <DatePicker
              aria-label={customLabels?.startDate ?? t('AssignDialogForm.StartDateLabel')}
              data-automation-id="StartDatePicker"
              format="dd-MM-yyyy"
              label={customLabels?.startDate ?? t('AssignDialogForm.StartDateLabel')}
              minDate={new Date()}
              onChange={(value) => updateForm({ startDate: value })}
              value={form.startDate}
              slotProps={{
                textField: { size: 'small', id: 'start-date-picker' },
              }}
            />
          </Stack>
          <Stack flex={1}>
            <DatePicker
              aria-label={customLabels?.endDate ?? t('AssignDialogForm.EndDateLabel')}
              data-automation-id="EndDatePicker"
              format="dd-MM-yyyy"
              label={customLabels?.endDate ?? t('AssignDialogForm.EndDateLabel')}
              minDate={form.startDate ?? new Date()}
              onChange={(value) => updateForm({ endDate: value })}
              value={form.endDate}
              slotProps={{
                textField: { size: 'small', id: 'end-date-picker' },
              }}
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
