import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from 'src/components/mui-components';

const ToastCloseButton = ({ closeToast }: Partial<CloseButtonProps>) => (
  <IconButton
    onClick={closeToast}
    title="Close"
    style={{
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
    }}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    autoClose={false}
    hideProgressBar
    newestOnTop
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<ToastCloseButton />}
    icon={false}
    style={{ zIndex: 20 }}
    bodyStyle={{ padding: 0 }}
    toastStyle={{ padding: 0 }}
  />
);
