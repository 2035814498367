import { useEffect, useState } from 'react';

/**
 * Hook to get window size on window resize.
 *
 * Delayed by 500ms for better performance.
 */
export const useGetWindowSize = () => {
  const [id, setId] = useState<NodeJS.Timer>();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setId(
        setTimeout(
          () =>
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            }),
          500,
        ),
      );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(Number(id));
    };
  }, [id]);

  return { h: windowSize.height, w: windowSize.width };
};
