import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetHourlyRates } from 'src/apis/resourcePlannerAPI';
import { useCustomEditComponent } from 'src/screens/ResourcePlanner/hooks';
import { TEditableHourlyRateRow } from '../EditableComponents.types';
import { HourlyRateDropdown } from '../HourlyRateDropdown/HourlyRateDropdown';

export const EditableLazyLoadHourlyRate = (props: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation('assignFlow');

  const { id, name, row: r, value } = props;
  const row = r as TEditableHourlyRateRow;

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { handleChange } = useCustomEditComponent<string>(props);

  const enabled = apiRef.current.isRowSelected(id) || isHovered || isOpen;

  const { data: hr, isFetching } = useGetHourlyRates(
    { taskId: row.workItemSourceReferenceId },
    enabled,
  );
  const hourlyRates = useMemo(() => (hr ?? []).map(({ properties }) => properties), [hr]);

  useEffect(() => {
    apiRef.current.setEditCellValue({
      id,
      field: 'hourlyRate',
      value: row.suggestedHourlyRateId,
    });
  }, [apiRef, id, row.suggestedHourlyRateId]);

  return (
    <HourlyRateDropdown
      aria-label={t('EditableComponents.HourlyRateLabel', { name })}
      hourlyRates={hourlyRates}
      id={String(id)}
      isFetching={isFetching}
      isShownHourlyRateDropDown={row.isShownHourlyRateDropDown}
      onChange={(e) => handleChange(String(e.target.value))}
      onClose={() => setIsOpen(false)}
      onMouseOver={() => setIsHovered(true)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      suggestedHourlyRateAmount={row.suggestedHourlyRateAmount}
      suggestedHourlyRateId={row.suggestedHourlyRateId}
      suggestedHourlyRateName={row.suggestedHourlyRateName}
      value={value}
    />
  );
};
