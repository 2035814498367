import cx from 'classnames';
import { Box, Tooltip, Typography } from 'src/components/mui-components';
import { getAvailabilityColor } from 'src/screens/ResourcePlanner/helper/getAvailabilityColor';
import { useDebounce } from 'use-debounce';
import styles from './HoursBar.module.scss';

interface IHoursBar {
  addedHours: number;
  availableHours: number;
  totalHours: number;
  tooltip?: Partial<{
    default: string;
    exceededAvailableHours: string;
  }>;
}

export const HoursBar = ({ addedHours = 0, availableHours, totalHours, tooltip }: IHoursBar) => {
  const utilizedHours = totalHours - availableHours;
  const utilizedProgress = totalHours === 0 ? 0 : utilizedHours / totalHours;
  const [debouncedAddedHours] = useDebounce(addedHours, 500);
  const addedProgress = totalHours === 0 ? 1 : debouncedAddedHours / totalHours;
  const exceededAvailableHours = debouncedAddedHours && availableHours - debouncedAddedHours < 0;
  const tooltipTitle = exceededAvailableHours ? tooltip?.exceededAvailableHours : tooltip?.default;

  return (
    <Tooltip followCursor title={tooltipTitle}>
      <Box className={styles.ProgressBarBg}>
        <Box
          className={cx(styles.Filled, utilizedProgress ? styles.HasProgress : '')}
          sx={{
            borderColor: getAvailabilityColor(utilizedProgress),
            width: `${utilizedProgress * 100}%`,
          }}
        />
        <Box
          className={cx(styles.Added, debouncedAddedHours ? styles.HasAdded : '')}
          sx={{ width: `${addedProgress * 100}%` }}
        />
        <Box className={cx(styles.WarningBorder, exceededAvailableHours ? styles.Visible : '')} />
        <Typography className={styles.Hours}>
          {(availableHours - debouncedAddedHours).toFixed(2)} / {totalHours.toFixed(2)} h
        </Typography>
      </Box>
    </Tooltip>
  );
};
