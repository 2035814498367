import { Divider, MenuItem, MenuList } from '@mui/material';
import { format } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import { ElementRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import {
  IBulkAssignReq,
  useGetCapacity,
  useGetGroupByProject,
  usePostBulkAssign,
} from 'src/apis/resourcePlannerAPI';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { IResourcePlannerItem } from 'src/apis/types/resourcePlannerAPI';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LoadingButton,
  Stack,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { convertObjectValueToNumber } from 'src/screens/ResourcePlanner/helper/convertObjectValueToNumber';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { useResourcePlannerStore } from 'src/stores/ResourcePlannerStore/ResourcePlannerStore';
import { useDebounce } from 'use-debounce';
import { AssignDialogSearch } from '../AssignDialogSearch';
import { IAssignDialogSearchForm } from '../AssignDialogSearch/AssignDialogSearch.types';
import { EmployeeCard } from '../EmployeeCard';
import { TaskTable } from '../TaskTable';
import { ToastTrans } from '../ToastTrans';
import styles from './AssignResourceToProjectDialog.module.scss';

interface IAssignResourceToProjectDialog {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
}

export const AssignResourceToProjectDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
}: IAssignResourceToProjectDialog) => {
  type TTaskTableHandle = ElementRef<typeof TaskTable>;
  const taskTableRef = useRef<TTaskTableHandle>(null);

  const { t } = useTranslation('assignFlow');
  const confirm = useConfirm();
  const { dateEnd, selectedViewOptions } = useResourcePlannerStore();
  const { confirmOptions, resourceSourceReferenceId, name } = useAssignFlowStore();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [debouncedStartDate] = useDebounce(startDate, 500);
  const [endDate, setEndDate] = useState<Date | null>(dateEnd);
  const [debouncedEndDate] = useDebounce(endDate, 500);
  const [selectedProject, setSelectedProject] = useState<IResourcePlannerItem>();
  const [hoursList, setHoursList] = useState<{
    [id: string]: string;
  }>({});
  const addedHours = Object.values(hoursList)
    .filter((h) => !Number.isNaN(Number(h)))
    .reduce((a, b) => a + Number(b), 0);

  const {
    children: projectList,
    isError: projectListIsError,
    isFetching: projectListIsFetching,
  } = useGetGroupByProject({ selectedFilterList: {} }, selectedViewOptions);

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList } = useGetFilterAPI(pageIdentifier);
  const flattenedFilterItemsList = filterList.reduce(
    (a, { filterItems }) => [...a, ...(filterItems ?? [])],
    [] as IFilterItemProperties[],
  );
  const projectFilter = flattenedFilterItemsList.find(({ name: n }) => n === 'Project');
  const {
    dropdownList,
    isError: dropdownListIsError,
    isFetching: dropdownListIsFetching,
  } = useGetDropdownWithSearchAPI({
    key: `filterInput${projectFilter?.id}`,
    MSWKey: `FILTER_INPUT_${projectFilter?.id}`,
    path: `/${projectFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: debouncedQuery,
    startDate: format(debouncedStartDate ?? new Date(), 'yyyy-MM-dd'),
    endDate: format(debouncedEndDate ?? dateEnd, 'yyyy-MM-dd'),
    enabled: dialogIsOpen && !!projectFilter,
  });

  const { data: capacity, isFetching: capacityIsFetching } = useGetCapacity(
    {
      periodEndDate: endDate ?? dateEnd,
      periodStartDate: startDate ?? new Date(),
      userId: resourceSourceReferenceId,
    },
    dialogIsOpen,
  );

  const isError = projectListIsError || dropdownListIsError;
  const isFetching = projectListIsFetching || dropdownListIsFetching || capacityIsFetching;

  const cleanUp = useCallback(() => {
    setEndDate(dateEnd);
    setHoursList({});
    setDialogIsOpen(false);
    setPage(1);
    setQuery('');
    setSelectedProject(undefined);
    setStartDate(new Date());
  }, [dateEnd, setDialogIsOpen]);

  const getSelectedTasks = useCallback(() => taskTableRef.current?.getFormValues() ?? [], []);

  const dialogOnClose = () => {
    if (selectedProject || getSelectedTasks().length) {
      confirm(confirmOptions).then(cleanUp);
      return;
    }

    cleanUp();
  };

  const backButtonOnClick = useCallback(() => {
    if (page === 2) {
      setHoursList({});
    }

    setPage((prev) => prev - 1);
  }, [page]);

  const projectLabel = useMemo(() => {
    if (page === 2 && selectedProject) {
      return selectedProject.name;
    }

    return t('Project');
  }, [page, selectedProject, t]);

  const bulkAssignOnSuccess = () => {
    cleanUp();
    toast.success(
      <ToastifyAlert
        title={t('Toast.SuccessTitle')}
        description={<ToastTrans l="Toast.AssignXtoYSuccess" t={t} x={name} y={projectLabel} />}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const { mutate: postBulkAssign, isLoading } = usePostBulkAssign(bulkAssignOnSuccess);

  const nextButtonOnClick = useCallback(() => {
    if (!selectedProject) {
      return;
    }

    if (page === 1) {
      setPage(2);
    }

    if (!getSelectedTasks()?.length) {
      return;
    }

    const assignReqBody: IBulkAssignReq[] = getSelectedTasks().map((task) => ({
      allocationEndDate: format(new Date(task.allocationEndDate), 'yyyy-MM-dd'),
      allocationStartDate: format(new Date(task.allocationStartDate), 'yyyy-MM-dd'),
      ...convertObjectValueToNumber({
        budgetHours: task.hours ?? 0,
        hourlyRateId: task.hourlyRate || task.suggestedHourlyRateId,
        projectId: selectedProject.workItemSourceReferenceId,
        resourceSourceReferenceId,
        workItemSourceReferenceId: task.workItemSourceReferenceId,
      }),
    }));

    postBulkAssign(assignReqBody);
  }, [getSelectedTasks, page, postBulkAssign, resourceSourceReferenceId, selectedProject]);

  const getDropdownListLabel = useCallback(
    (label: string) => label.replace(')', '').split(' (').join(' · '),
    [],
  );

  const projectLabelElement = useMemo(() => {
    if (page === 2 && selectedProject) {
      return <b>{projectLabel}</b>;
    }

    return projectLabel;
  }, [page, projectLabel, selectedProject]);

  const setSearchForm = ({ endDate: e, query: q, startDate: s }: IAssignDialogSearchForm) => {
    setEndDate(e);
    setQuery(q);
    setStartDate(s);
  };

  const dialogTitle = useMemo(() => {
    if (page === 2) {
      return (
        selectedProject &&
        capacity && (
          <Box className={styles.EmployeeCard}>
            <EmployeeCard
              addedHours={addedHours}
              capacity={capacity.capacity}
              department={capacity.department}
              fullName={capacity.fullName}
              legalEntity={capacity.legalEntity}
              position={capacity.position}
            />
            <Divider className={styles.Divider} />
          </Box>
        )
      );
    }

    return (
      <>
        <Grid container className={styles.FormGrid} columnSpacing={5.75} rowSpacing={2}>
          <Grid item xs={6}>
            <AssignDialogSearch
              customLabels={{
                search: t('AssignResourceToProjectDialog.SearchForProject'),
              }}
              form={{ query, startDate, endDate }}
              setForm={setSearchForm}
            />
          </Grid>
        </Grid>
        <Divider className={styles.Divider} />
      </>
    );
  }, [addedHours, capacity, endDate, page, query, selectedProject, startDate, t]);

  const dialogContent = useMemo(() => {
    if (page === 2) {
      return (
        selectedProject && (
          <Box className={styles.TableContainer}>
            <TaskTable
              endDate={endDate ?? dateEnd}
              projectId={selectedProject.sourceReferenceId}
              ref={taskTableRef}
              startDate={startDate ?? new Date()}
              setHoursList={setHoursList}
              userId={resourceSourceReferenceId}
            />
          </Box>
        )
      );
    }

    return (
      <ResponseHandler
        EmptyComponent={
          <Typography textAlign="center">{t('AssignResourceToProjectDialog.NoResults')}</Typography>
        }
        isEmpty={!dropdownList.length}
        isError={isError}
        isLoading={isFetching}
        LoadingComponent={<CircularProgress />}
      >
        <MenuList className={styles.Menu}>
          {dropdownList.map((p) => (
            <MenuItem
              className={styles.Project}
              key={p.value}
              onClick={() => {
                const selected = projectList.find((i) => i.workItemSourceReferenceId === p?.value);
                if (!selected) {
                  return;
                }
                setSelectedProject((prev) => {
                  if (prev?.workItemSourceReferenceId === p.value) {
                    nextButtonOnClick();
                  }
                  return {
                    ...selected,
                    name: p.label,
                  };
                });
              }}
              selected={p.value === selectedProject?.workItemSourceReferenceId}
            >
              <HighlightMatchingText matchName={query} name={getDropdownListLabel(p.label)} />
            </MenuItem>
          ))}
        </MenuList>
      </ResponseHandler>
    );
  }, [
    dateEnd,
    dropdownList,
    endDate,
    getDropdownListLabel,
    isError,
    isFetching,
    nextButtonOnClick,
    page,
    projectList,
    query,
    resourceSourceReferenceId,
    selectedProject,
    startDate,
    t,
  ]);

  const dialogActions = useMemo(() => {
    if (page === 2) {
      return (
        <Stack direction="row" flex={1} justifyContent="space-between">
          <Button disabled={isLoading} onClick={backButtonOnClick}>
            {t('Back')}
          </Button>
          <Stack direction="row">
            <Button disabled={isLoading} onClick={cleanUp} variant="outlined">
              {t('Cancel')}
            </Button>
            <LoadingButton isLoading={isLoading} onClick={nextButtonOnClick} variant="contained">
              {t('Assign')}
            </LoadingButton>
          </Stack>
        </Stack>
      );
    }

    return (
      <>
        <Button onClick={cleanUp} variant="outlined">
          {t('Cancel')}
        </Button>
        <Button disabled={!selectedProject} onClick={nextButtonOnClick} variant="contained">
          {t('Next')}
        </Button>
      </>
    );
  }, [backButtonOnClick, cleanUp, isLoading, nextButtonOnClick, page, selectedProject, t]);

  useEffect(() => {
    setEndDate(dateEnd);
  }, [dateEnd]);

  return (
    <Dialog
      fullWidth
      maxWidth={page === 1 ? 'md' : 'xl'}
      onClose={dialogOnClose}
      open={dialogIsOpen}
      PaperProps={{
        sx: {
          height: page === 1 ? 'auto' : '100%',
        },
      }}
    >
      <DialogTitle>
        <Box className={styles.DialogTitle}>
          <Trans
            i18nKey="AssignXtoY"
            key="AssignXtoY"
            defaults={t('AssignXtoY')}
            values={{ x: name, y: projectLabel }}
            components={[<b key={0}>{name}</b>, projectLabelElement]}
          />
        </Box>
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={styles.DialogContent}>{dialogContent}</DialogContent>
      <DialogActions className={styles.DialogActions}>{dialogActions}</DialogActions>
    </Dialog>
  );
};
