import { AlertProps as MuiAlertProps } from '@mui/material';
import { ReactNode } from 'react';
import { ToastContentProps } from 'react-toastify';
import { Alert } from '../Alert';
import { Typography } from '../Typography';
import styles from './ToastifyAlert.module.scss';

export interface IToastifyAlertProps extends MuiAlertProps, Partial<ToastContentProps> {
  title?: string;
  description: ReactNode;
  customIcon?: ReactNode;
}

export const ToastifyAlert = ({
  title,
  description,
  customIcon,
  toastProps,
  severity,
  ...rest
}: IToastifyAlertProps) => {
  const getSeverity = () => {
    if (severity) {
      return severity;
    }

    switch (toastProps?.type) {
      case 'default':
        return 'info';

      default:
        return toastProps?.type;
    }
  };

  return (
    <Alert {...rest} icon={customIcon} severity={getSeverity()} className={styles.ToastifyAlert}>
      {title && <Typography fontWeight={600}>{title}</Typography>}
      <Typography>{description}</Typography>
    </Alert>
  );
};
