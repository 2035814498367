import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { TEmployeeTableRow } from '../EmployeeTable';
import { HoursBar } from '../HoursBar';
import styles from './EmployeeCard.module.scss';

type TEmployeeCard = Pick<
  TEmployeeTableRow,
  'capacity' | 'department' | 'legalEntity' | 'fullName' | 'position'
> & {
  addedHours?: number;
  query?: string;
};

export const EmployeeCard = ({
  addedHours = 0,
  capacity = {
    availableHours: 0,
    totalHours: 0,
  },
  department = '',
  legalEntity = '',
  fullName = '',
  position = '',
  query = '',
}: TEmployeeCard) => {
  const { t } = useTranslation('assignFlow');

  const getText = (text: string) =>
    text ? (
      <Typography fontSize={12} lineHeight={1}>
        {text}
      </Typography>
    ) : null;

  const getSeparator = (condition1?: string, condition2?: string) =>
    condition1 && condition2 ? getText('·') : null;

  return (
    <Stack className={styles.EmployeeCard} direction="row" gap={2}>
      <Box className={styles.Avatar} />
      <Stack flex={1}>
        <Typography lineHeight={1}>
          <HighlightMatchingText matchName={query} name={fullName} />
        </Typography>
        <Stack direction="row" flexWrap="wrap">
          {getText(position)}
          {getSeparator(position, department)}
          {getText(department)}
          {getSeparator(department, legalEntity)}
          {getText(legalEntity)}
        </Stack>
        <HoursBar
          addedHours={addedHours}
          availableHours={capacity.availableHours}
          totalHours={capacity.totalHours}
          tooltip={{
            default: t('EmployeeCard.TooltipDefault'),
            exceededAvailableHours: t('EmployeeCard.TooltipExceededHours'),
          }}
        />
      </Stack>
    </Stack>
  );
};
