import { IEmployeeSearchResProperties } from 'src/apis/resourcePlannerAPI';
import { Box } from 'src/components/mui-components';
import { CompetenceList } from '../CompetenceList';
import { EmployeeCard } from '../EmployeeCard';
import styles from './EmployeeWithCompetence.module.scss';

interface IEmployeeWithCompetence {
  resource: IEmployeeSearchResProperties;
  addedHours?: number;
  onClick?: (r: IEmployeeSearchResProperties) => void;
  query?: string;
}

export const EmployeeWithCompetence = ({
  addedHours,
  onClick,
  query,
  resource,
}: IEmployeeWithCompetence) => (
  <Box className={styles.GridBox} onClick={() => onClick?.(resource)}>
    <Box className={styles.Box}>
      <EmployeeCard
        addedHours={addedHours}
        capacity={resource.capacity}
        department={resource.department}
        legalEntity={resource.legalEntity}
        fullName={resource.fullName}
        position={resource.position}
        query={query}
      />
    </Box>
    <Box className={styles.Box}>
      <CompetenceList competenceList={resource.competences ?? []} />
    </Box>
  </Box>
);
