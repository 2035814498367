import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import {
  IAssignDialogFormFilters,
  IAssignDialogFormForm,
} from 'src/screens/ResourcePlanner/components/AssignFlow/components/AssignDialogForm';
import { postFetch } from 'src/utils/fetcher';
import { TEmployeeSearchReq, TEmployeeSearchRes } from './postEmployeeSearchAPI.types';

const POST_EMPLOYEE_SEARCH_URL_PATH = '/api/v2/resource-planner/employee-search';
const POST_EMPLOYEE_SEARCH_KEY = 'EmployeeSearch';

type TPostEmployeeSearch = Partial<IAssignDialogFormForm & IAssignDialogFormFilters>;

const postEmployeeSearch = (reqBody: TEmployeeSearchReq): Promise<TEmployeeSearchRes> =>
  postFetch({
    path: POST_EMPLOYEE_SEARCH_URL_PATH,
    key: POST_EMPLOYEE_SEARCH_KEY,
    body: JSON.stringify(reqBody),
  });

export const usePostEmployeeSearch = (
  {
    competence,
    department,
    endDate,
    hours,
    legalEntity,
    manager,
    query,
    startDate,
  }: TPostEmployeeSearch,
  enabled?: boolean,
) => {
  const reqBody: TEmployeeSearchReq = {
    SearchText: query,
    PeriodStartDate: format(startDate ?? new Date(), 'yyyy-MM-dd'),
    PeriodEndDate: format(endDate ?? new Date(), 'yyyy-MM-dd'),
    Hours: hours,
    ManagerIds: manager ? manager.values.map((d) => d.value).toString() : undefined,
    IsManagerInclusive: manager?.values.length ? manager.isInclude : undefined,
    DepartmentIds: department?.length ? department.map((d) => d.value).toString() : undefined,
    IsDepartmentInclusive: department?.length ? true : undefined,
    LegalEntityIds: legalEntity ? legalEntity.values.map((d) => d.value).toString() : undefined,
    IsLegalEntityInclusive: legalEntity?.values.length ? legalEntity.isInclude : undefined,
    Competences: competence?.length
      ? competence.map((c) => ({
          Id: c.value,
          Value: c.levels.toString(),
        }))
      : undefined,
    IsCompetenceInclusive: competence?.length ? true : undefined,
  };

  const filteredReqBody = Object.keys(reqBody).reduce((a, b) => {
    const key = b as keyof TEmployeeSearchReq;
    if (reqBody[key] !== undefined && reqBody[key] !== null && reqBody[key] !== '') {
      return { ...a, [key]: reqBody[key] };
    }
    return a;
  }, {} as TEmployeeSearchReq);

  const {
    data: { entities } = {},
    isError,
    isFetching,
  } = useQuery(
    [POST_EMPLOYEE_SEARCH_KEY, filteredReqBody],
    () => postEmployeeSearch(filteredReqBody),
    {
      enabled,
    },
  );

  return { data: entities, isError, isFetching };
};
